// BOOTSTRAP DEFAULT OVERRIDES

// This file contains changes Marco would like to make to override the
// default styles provided by Bootstrap.  This is the recommended 
// configuration approach described by Bootstrap's documentation.

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$custom-file-text: ();

// Newest brand colors 4/17/2024
$brand-info:  #101c41;
$brand-primary:#007AB8;
$brand-secondary: #636769;
$brand-primary-hover: #00A2E1;
$brand-accent: #e6e6e6;
$brand-success:         #03821E;
$brand-warning:         #B36200;
$brand-danger:          #E10F00;

// Marco 3.0 Brand colors as expressed as BS variables
$brand-hilight:         #E3F1FF;

$gray:                 #888888;
$gray-lighter:           #f5f5f5;
$gray-dark:             #888888;
$user-ava-size:         2em;
$white-color:           white;
$btn-sm-height:         1.2em;
$font-size-xs:          .7em;

// override default bootstrap styles with Marco
$theme-colors: (
    primary: $brand-primary,
    info: $brand-info
)


