/* You can add global styles to this file, and also import other style files */

@import "~/src/bs-overrides";
@import "~bootstrap/scss/bootstrap.scss";
// @import "src/assets/sass/styles.scss";

@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/bootstrap.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';


$header-tags: #212529;

a {
  color: $brand-primary;
}

a:hover,
.link-style:hover {
  text-decoration: underline !important;
}

#content {
  margin: 0 auto;
  padding-top: 40px;
  min-height: 300px;
  position: relative;
}

html {
  font-family: 'Exo', sans-serif !important;
  font-size: 17px;
  line-height: 1.6;
  font-weight: 500;
}

body {
  font-family: 'Exo', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-weight: 600;
  color: $brand-info;
}

h1 { font-size: 40px; }
h2 { font-size: 32px; }
h3 { font-size: 28px; }
h4 { font-size: 24px; }
h5 { font-size: 20px; }
h6 { font-size: 18px; }

// NOTE: this is a little bit of a hack;
//  this solves FOUC everywhere we use FA.
//  Angular-fontawesome (and react-fontawesome) 
//  use javascript to generate the below styles
//  at runtime, therefore making it impossible
//  for angular's inlineCritical CSS extractor
//  to pull this into the server side rendering
//  By hardcoding this as a shim to make this work
//  we fix this problem however be warned that if 
//  FA changes their style system, this may break.

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}
fa-icon .fa-2x{
  height: 2em;
}

.scroll-y {
  overflow-y: scroll;
}

.scroll-x { overflow-x: auto; }

.link-style {
  color: $brand-primary !important;
  cursor: pointer;
}


// Table hover
.table-hover {
  tbody tr:hover { background-color: $gray-lighter; }
  &.table-inverse {
    tbody tr:hover { background-color: rgba(black, .08); }
  }
}

.text-link { color: $brand-primary !important; cursor: pointer }
.text-bold { font-weight: bold !important; }
.text-white { color: white !important; }
.text-black { color: black !important; }
.color-secondary { color: $brand-secondary; }
.text-green { color: #03821E }
.text-gray-dark { color: $gray-600 !important; }

.responsive-image {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.row-column-responsive {
  display: flex;
  flex-direction: row;

  @media(max-width: 1025px) { flex-direction: column; }
}

.flex-center-responsive {
  @media(max-width: 1025px) { align-items: center }
}

.object-fit-contain { object-fit: contain; }
.object-fit-cover { object-fit: cover; }

.bg-info { background-color: $brand-info }
.bg-table { background-color: #fafafa }
.bg-callout { background-color: #E6E6E6 }
.bg-white { background-color: white;}
.bg-gray { background-color: #ededed;}

.add-flex { display: flex; }
.add-grid { display: grid; }
.d-flex { display: flex !important;}
.flex-1 { flex: 1 };
.flex-row { flex-direction: row; }
.flex-column { flex-direction: column;}
.justify-content-between { justify-content: space-between; }
.justify-content-center { justify-content: center; }
.align-end { align-items: end };
.flex-align-bottom { align-items: flex-end; }
.flex-align-start { align-items: flex-start; }
.flex-wrap { flex-flow: wrap }
.align-self-center { align-self: center; }

.hr { border-top: 1px solid rgba(0, 0, 0, 0.125) }

.mr10 { margin-right: 10px !important }
.mb20 { margin-bottom: 20px !important }

.flex-10-pct { flex: 0 0 10% }
.flex-15-pct { flex: 0 0 15% }
.flex-25-pct { flex: 0 0 25% }
.flex-30-pct { flex: 0 0 30% }
.flex-33-pct { flex: 0 0 33% }
.flex-45-pct { flex: 0 0 45% }
.flex-50-pct { flex: 0 0 50% }
.flex-55-pct { flex: 0 0 55% }
.flex-66-pct { flex: 0 0 66% }
.flex-75-pct { flex: 0 0 75% }
.flex-100-pct { flex: 0 0 100% }

.align-center { align-items: center; }

.pointer { cursor: pointer !important }

.gap-10 { gap: 10px; }
.gap-20 { gap: 20px; }
.rg-10 { row-gap: 10px;}
.cg-5 { column-gap: 5px;}
.cg-10 { column-gap: 10px;}
.mb-30 { margin-bottom: 30px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-6 { margin-bottom: 6px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-0 { margin-bottom: 0px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-5 { margin-top: 5px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-0 { margin-right: 0px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-5 { margin-left: 5px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-0 { padding-top: 0px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-12 { padding-bottom: 12px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-20 { padding-right: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-0 { padding-left: 0px !important; }
.pl-75rem { padding-left: 0.75rem !important; }
.m-5 { margin: 5px !important; }
.m-10 { margin: 10px !important; }
.m-20 { margin: 20px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.p-20 { padding: 20px !important; }
.p-50 { padding: 50px !important; }
.p-0 { padding: 0px !important; };
.px-15 { padding-left: 15px; padding-right: 15px;}
.py-0 { padding-top: 0px; padding-bottom: 0px;}

.text-align-center { text-align: center; }
.text-align-left { text-align: left; }

.list-style-none { list-style-type: none; }

.bb-0 { border-bottom: 0px !important; }

.uppercase { text-transform: uppercase !important; }
.transform-none { text-transform: none !important; }

.font-weight-550 { font-weight: 550 !important }

.font-12 { font-size: 12px !important }
.font-16 { font-size: 16px !important }
.font-26 { font-size: 26px !important }

.font-white { color: white !important }
.font-navy { color: $brand-info !important }

.bg-no-repeat {  background-repeat: no-repeat; }
.bg-cover { background-size: cover; }
.grid-column-span-2 { grid-column: span 2;}

.background-gray-light { background-color: $gray-lighter }

.brand-link { color: #007AB8 !important; cursor: pointer }

.position-static { position: static !important }
.position-relative { position: relative !important }
.position-absolute { position: absolute !important }

.width-100 { width: 100% }

.border-0 { border: 0px !important }
.border-radius-0 { border-radius: 0px !important }
.border-1-accent { border: 1px solid $brand-accent }

.btn-primary:hover {
  background-color: $brand-primary-hover;
  border-color: $brand-primary-hover;
}
.btn-outline-primary:hover {
  background-color: $brand-primary;
  color: white;
  border-color: $brand-primary;
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.ag-theme-quartz {
  --ag-foreground-color: black;
  --ag-header-background-color: #fafafa;
  --ag-border-color: #d9d9d9;
  --ag-odd-row-background-color: #fafafa;
  --ag-wrapper-border-radius: 0px;
  --ag-cell-horizontal-padding: 10px;
  --ag-font-size: 14px;
  --ag-font-family: 'Exo', sans-serif;

  .ag-cell-value {
    line-height: 25.5px;
    word-break: normal;
  }

  .ag-header-cell-text {
    font-weight: 600;
    font-size: 14px;
  }

  .ag-header-icon { display: none; }

  // .ag-floating-filter-body { display: none; }
  // .ag-floating-filter { justify-content: center; }
  // .ag-floating-filter-button {  margin-left: 0px; }
  // .ag-icon-filter { color: white !important;}
  // --ag-row-border-color: orange;
  // .ag-header-row-column-filter { background-color: white; }
  // .ag-floating-filter { border-top-color: green !important}
  // --ag-header-column-resize-handle-color: white;
  // --ag-background-color: white;
  // --ag-header-foreground-color: white;

  .ag-grid-border-right { border-right: 1px solid #d9d9d9 !important; }
}

.related-links-grid-2-column {
  grid-template-columns: repeat(2, [col-start] 1fr);
  grid-gap: 10px;

  @media(max-width: 993px) {
    grid-template-columns: repeat(1, [col-start] 1fr);
  }
}

.related-links-grid-3-column {
  grid-template-columns: repeat(3, [col-start] 1fr);
  grid-gap: 10px;

  @media(max-width: 993px) {
    grid-template-columns: repeat(1, [col-start] 1fr);
  }
}

.grid-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); 
  grid-gap: 1em;
  .grid-box {
    border: 1px solid #D1D1D1;
  }
}

.responsive-grid-list {
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr);

  @media(max-width: 993px) {
    grid-template-columns: repeat(1, [col-start] 1fr);
  }
}

/* Remove rounded corners from accordians */
.panel.card { border-radius: 0px !important; }

/* Left align panel header text */
.accordion-toggle.btn { text-align: left; }
.accordion-toggle > button { text-align: left; }

/* Sets global buttons color */
.btn-link { color: $brand-primary }


.form-control-feedback{ color:red; }
.form-control-danger{ border:1px solid red; }
















/* Old Styles need to review or move to specific components */
.product-item {
  display: table;
  width: 100%;
  min-width: 150px;
  .product-thumb,
  .product-info {
    display: table-cell;
    vertical-align: top;
  }
  .product-thumb {
    // padding-right: 20px;
    > img {
      max-width: 44px;
      display: block;
    }
  }
  .product-info span {
    display: block;
    font-size: 1em;
    > em {
      font: {
        weight: 500;
        style: normal;
      }
    }
  }
  .product-title {
    margin-bottom: 6px;
    padding-top: 0px;
    font: {
      size: 16px;
      weight: 600;
    }
    > a {
      transition: color .3s;
      color: $gray-dark;
      line-height: $line-height-base;
      text-decoration: none;
      &:hover { color: $brand-primary; }
    }
    small {
      display: inline;
      margin-left: 6px;
      font-size: 90%;
    }
  }
}

.global-table-container {
  max-height: 500px;
  overflow: scroll;

  .global-table {
    font-size: 16px !important;
  
    td, th {
      padding: 5px;
    }
  }
}

.red-text {
  color: #00a2e1;
  font-size: 16px;
}

.btn-tiny {
  height: 20px;
  font-size: 13px !important;
  padding: 0 10px !important;
}

.label-tiny {
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
  padding-right: 3px !important;
  padding-left: 0px !important;
}




.largeTitle {
  font-weight: bold;
  font-size: 2.067em; /*31*/
  line-height: 1.2;
  padding: 0px;
  margin-bottom: 18px;
  text-align: center;
}
.largeSubTitle {
  text-transform: uppercase;
  color: #dab760; /* v3 brand primary */
  font-size: 1.6em; /*24*/
  line-height: 1.2;
  font-weight: bold;
}
.pageTitle {
  font-weight: bold;
  font-size: 1.6em; /*24*/
  line-height: 1.2;
  padding: 0px;
  border-bottom: solid 1px #999999;
  margin-bottom: 18px;
  color: #036;
}
.title {
  text-transform: uppercase;
  color:#dab760; /* v3 brand primary */
  font-weight: bold;
  font-size: 1.333em; /*20*/
  line-height: 1.2;
}
.title span {
  color: #999;
  font-weight: 400;
}


.btn {  
  // text-transform: uppercase !important;
  font-weight: 600 !important;
}

.btn-quote {
  width: 112px;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.2;
  display: inline-block;
  background-color: #00a2e1;
  color: #fff;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-decoration: none;
  text-align: center;
}
.btn-quote:hover {
  color: #fff;
}


.btn-store {
  width: 112px;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.2;
  display: inline-block;
  color: #00a2e1;
  background-color: #fff;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-decoration: none;
  text-align: center;
}
.btn-store:hover {
  color: #00a2e1;
}


a.icon-btn {
  display: inline-block;
  width: 40px;
  height: 35px;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center top;
  vertical-align: middle;

  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
a.icon-btn:hover {
  opacity: 0.55;
  background-color: transparent !important;
}
a.icon-btn.data {
  background-image: url(/assets/site/images/chart-images/icon-data.png);
  width: 25px;
}



.nav-pills {
  button {
    background-color: $brand-primary;
  }
}


/* End old styles */